export default {
  name: "main-footer",
  methods: {
    scrollToTop(){
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      })
    }
  }
}
