
export default {
  name: "main-header",
  data() {
    return {
      burger: false,
      scroll: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.scroll = window.scrollY > 74
    })
    this.scroll = window.scrollY > 74
  },
  methods: {
    scrollTo(id){
      this.$nextTick(() => {
        const yOffset = -100
        const el = document.querySelector(`#${id}`)
        const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({
          top: y,
          behavior: "smooth",
        })
        this.burger = false
      })
    },
    scrollToTop(){
      this.$nextTick(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
      })
    }
  }
}
