<template>
  <div id="app" class="app">
    <div class="app-container">
      <transition name="component-fade" mode="out-in">
        <div class="app-loader" v-if="showLoader">
          <div class="app-loader-wrap">
            <svg width="123" height="40" viewBox="0 0 123 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20ZM8.04172 20C8.04172 26.6044 13.3956 31.9583 20 31.9583C26.6044 31.9583 31.9583 26.6044 31.9583 20C31.9583 13.3956 26.6044 8.04172 20 8.04172C13.3956 8.04172 8.04172 13.3956 8.04172 20Z" fill="#352E28"/>
              <path class="app-loader-path" d="M20.3161 4.02399C20.3601 1.80376 22.2141 -0.000761582 24.3808 0.485677C27.1825 1.11466 29.8319 2.34325 32.1388 4.10507C35.5178 6.68556 37.9865 10.2757 39.1866 14.3544C40.3868 18.4331 40.2559 22.7882 38.813 26.7875C37.3701 30.7868 34.6903 34.2222 31.1625 36.5951C27.6347 38.9681 23.4424 40.155 19.1943 39.9838C14.9461 39.8125 10.863 38.2919 7.53772 35.6426C4.21239 32.9934 1.81784 29.3534 0.701599 25.2509C-0.0605109 22.4499 -0.199662 19.5329 0.273975 16.7008C0.640272 14.5106 3.01062 13.4736 5.08359 14.2699V14.2699C7.15656 15.0663 8.11506 17.4155 8.04729 19.6351C8.01142 20.8101 8.14874 21.9911 8.46122 23.1396C9.12863 25.5925 10.5604 27.7689 12.5486 29.3529C14.5369 30.937 16.9782 31.8462 19.5182 31.9486C22.0583 32.051 24.5649 31.3413 26.6742 29.9225C28.7835 28.5037 30.3858 26.4496 31.2486 24.0583C32.1113 21.6671 32.1895 19.0632 31.472 16.6244C30.7544 14.1857 29.2783 12.0391 27.258 10.4962C26.3121 9.77383 25.2699 9.20138 24.1682 8.79166C22.0868 8.01764 20.2722 6.24422 20.3161 4.02399V4.02399Z" fill="#688BB7"/>
              <path class="app-loader-text" d="M43.82 27L50.06 13H53.26L59.52 27H56.12L51 14.64H52.28L47.14 27H43.82ZM46.94 24L47.8 21.54H55L55.88 24H46.94ZM60.9725 27V13H64.2125V24.36H71.2325V27H60.9725ZM73.0428 27V13H76.2828V24.36H83.3028V27H73.0428ZM86.7045 27V15.64H82.2245V13H94.4245V15.64H89.9445V27H86.7045ZM99.1478 18.64H105.888V21.16H99.1478V18.64ZM99.3878 24.4H107.008V27H96.1678V13H106.748V15.6H99.3878V24.4ZM109.586 27V13H115.646C116.899 13 117.979 13.2067 118.886 13.62C119.792 14.02 120.492 14.6 120.986 15.36C121.479 16.12 121.726 17.0267 121.726 18.08C121.726 19.12 121.479 20.02 120.986 20.78C120.492 21.5267 119.792 22.1 118.886 22.5C117.979 22.9 116.899 23.1 115.646 23.1H111.386L112.826 21.68V27H109.586ZM118.486 27L114.986 21.92H118.446L121.986 27H118.486ZM112.826 22.04L111.386 20.52H115.466C116.466 20.52 117.212 20.3067 117.706 19.88C118.199 19.44 118.446 18.84 118.446 18.08C118.446 17.3067 118.199 16.7067 117.706 16.28C117.212 15.8533 116.466 15.64 115.466 15.64H111.386L112.826 14.1V22.04Z" fill="#192F4C"/>
            </svg>
          </div>
        </div>
      </transition>
      <div class="wrapper">
        <div class="wrapper-top">
          <main-header/>
          <transition name="component-fade" mode="out-in">
            <router-view/>
          </transition>
        </div>
        <div class="wrapper-bottom">
          <main-footer/>
        </div>
      </div>
    </div>
    <hidden/>
  </div>
</template>

<style src="@/assets/scss/main.scss" lang="scss"></style>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import Hidden from '@/components/hidden/index.vue';
import MainHeader from '@/components/header/index.vue';
import MainFooter from '@/components/footer/index.vue';
import {isMobile, isIPad} from '@/helpers/variables/index'
import AOS from 'aos';
import 'aos/dist/aos.css';
export default {
  name: 'app',
  components: {
    Hidden,
    MainHeader,
    MainFooter
  },
  data(){
    return{
      showLoader: true,
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: `auth/isAuthenticated`,
      variables: `setting/variables`
    })
  },
  created() {
  },
  mounted() {
    if (!isMobile.any()) {
      document.querySelector('body').classList.add('hover');
    }
    if (!isIPad.iOS()) {
      document.querySelector('body').classList.add('iPad');
    }
    // setTimeout(()=>{
    //
    // },50)
    setTimeout(()=>{
      this.showLoader = false
      AOS.init({
        disable: function () {
          const maxWidth = 500;
          return window.innerWidth <= maxWidth;
        }
      });
      if(this.$route.hash.length > 0){
        this.scrollTo(this.$route.hash)
      }
    }, 1000)
  },
  methods: {
    ...mapMutations({
    }),
    ...mapActions({
    }),
    scrollTo(id){
      this.$nextTick(() => {
        const yOffset = -100
        const el = document.querySelector(`${id}`)
        const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({
          top: y,
          behavior: "smooth",
        })
      })
    },
  }
}
</script>
