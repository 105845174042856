import {$http} from '@/utils/https';

import {
  CHANGE_CALENDLY,
  CHANGE_LOADING_EMAIL,
  CHANGE_LOADING_FEEDBACK,
} from '../mutation-types';
import {
  SEND_EMAIL,
  SEND_FEEDBACK,
} from '../action-types';
const state = {
  showCalendly: false,
  loadingEmail: false,
  loadingFeedback: false,
};
const getters = {
  showCalendly: state => state.showCalendly,
  loadingEmail: state => state.loadingEmail,
  loadingFeedback: state => state.loadingFeedback,
};
const actions = {
  [SEND_EMAIL]: async ({commit}, payload) => {
    commit(CHANGE_LOADING_EMAIL, true);
    try {
      return await $http.post('v1/landing/subscribe', payload);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_EMAIL, false)
    }
  },
  [SEND_FEEDBACK]: async ({commit}, payload) => {
    commit(CHANGE_LOADING_FEEDBACK, true);
    try {
      return await $http.post('v1/landing/feedback', payload);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_FEEDBACK, false)
    }
  },
};
const mutations = {
  [CHANGE_CALENDLY](state, status) {
    state.showCalendly = status;
  },
  [CHANGE_LOADING_EMAIL](state, status) {
    state.loadingEmail = status;
  },
  [CHANGE_LOADING_FEEDBACK](state, status) {
    state.loadingFeedback = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};

